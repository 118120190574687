<template>
	<div class="myOrgStudy">
		<div class="selects">
		    <table class="table2">
		        <tr>
		            <td style="width:130px;text-align:right;padding-right:24px;">课程名称</td>
		            <td>
		                <el-select style="width:330px;" v-model="hand_task.course_ids" multiple placeholder="请选择">
		                    <el-option
		                    v-for="item in allCourse"
		                    :key="item.id"
		                    :label="item.title"
		                    :value="item.id">
		                    </el-option>
		                </el-select>
		            </td>
		        </tr>
		        <tr>
		            <td style="width:130px;text-align:right;padding-right:24px;">选择成员</td>
		            <td>
		                <el-select style="width:330px;" v-model="hand_task.student_ids" multiple placeholder="请选择">
		                    <el-option
		                    v-for="item in teamOrg"
		                    :key="item.id"
		                    :label="item.real_name"
		                    :value="item.id">
		                    </el-option>
		                </el-select>
		            </td>
		        </tr>
		        <tr>
		            <td style="width:130px;text-align:right;padding-right:24px;">计划完成时间</td>
		            <td>
		                <el-date-picker
		                 style="width:330px;"
		                v-model="hand_task.plan_time"
		                type="datetime"
		                :picker-options="pickerOptions"
		                value-format="yyyy-MM-dd HH:mm"
		                placeholder="选择日期时间">
		                </el-date-picker>
		            </td>
		        </tr>
		    </table>
		</div>
		<button @click="handleSend">提交</button>
		
		<el-dialog
		    :visible.sync="parsonErrorHidden"
		    width="700px"
		    center>
		    <div style="width:100%;text-align:left;">
		        <p v-for="(im,ix) in parsonError" :key="ix" style="line-height:26px;font-size:16px;color:#333;">{{im.msg}}</p>
		    </div>
		    <div slot="footer">
		        <el-button @click="parsonErrorHidden = false">关 闭</el-button>
		    </div>
		</el-dialog>
	</div>
</template>

<script>
	import { respon_people, hand_task } from '@/utils/Api/userList'
	import { all_course } from '@/utils/Api/commonList'
	export default{
		name:'myOrgStudy',
		data(){
			return{
				hand_task:{},
				pickerOptions: {disabledDate: (time) => {return this.dealDisabledDate(time)}},
				teamOrg:[],
				allCourse:[],
				parsonError:[],     // 派发失败人员
				parsonErrorHidden: false,   // 派发失败弹窗
			}
		},
		created() {
			this.initial()
		},
		methods:{
			initial(){
				let params = {
					page: this.page,
					limit: 10,
				}
				respon_people(params).then(res => {
					res.res.forEach((im,ix) => {
						im.real_name = im.real_name ? im.real_name + '_' + im.phone_tail : im.name + '_' + im.phone_tail
					})
					this.teamOrg = res.res
				})
				all_course().then(res => {
					this.allCourse = res
				})
			},
			handleSend(){
				if(this.hand_task.course_ids.length < 1){
				    this.$message.error('请选择课程名称！')
				    return
				}
				if(this.hand_task.student_ids.length < 1){
				    this.$message.error('请选择成员！')
				    return
				}
				if(!this.hand_task.plan_time){
				    this.$message.error('请选择结束时间！')
				    return
				}
				hand_task({
				    course_ids: this.hand_task.course_ids.join(','),
				    student_ids: this.hand_task.student_ids.join(','),
				    plan_time: this.hand_task.plan_time
				}).then(cres => {
					let res = cres.data
					if(res.length < 1){
						this.$message.success('学习任务派发成功')
						this.hand_task.course_ids = []
						this.hand_task.student_ids = []
						this.hand_task.plan_time = ''
						this.hand_task = {}
						return
					}
					this.$confirm('部分人员派发失败，是否查看失败原因？',{
						cancelButtonText:'不用了',
						confirmButtonText:'去看看',
						type: 'warning'
					}).then(()=>{
						this.parsonError = res
						this.parsonErrorHidden = true
					}).catch(()=>{
					
					})
					this.hand_task = {}
				})
			},
			dealDisabledDate (time) {
			    return time.getTime() < Date.now() - 8.64e7 || time.getTime() > Date.now() + (86400000 * 30 * 24)
			},
		}
	}
</script>

<style lang="scss" scoped="scoped">
	button{
		width: 80px;
		height: 36px;
		background: #47D7E3;
		color: #FFFFFF;
		margin-left: 30px;
		margin-top: 30px;
		cursor: pointer;
	}
	.myOrgStudy{
		text-align: left;
	}
	.table2{
	    tr{
	        height: 60px;
	    }
	}
</style>
